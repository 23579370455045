import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { checkUserExist, insertUser, getUser } from '../api/UsersContainer';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext({
    user: null,
    login: () => { },
    logout: () => { },
    isAuthenticated: false,
    accessToken: null,
    refreshToken: () => { },
    setAccessToken: () => { },
    role: null,
    validateToken: () => { },
});

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
        authority: process.env.REACT_APP_AZURE_AUTHORITY_URI,
        redirectUri: window.location.origin,
    },
    scopes: ["Files.ReadWrite.All", "user.read"],
};

export const AuthProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState(null);
    const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false);
    const [msalInitialized, setMsalInitialized] = useState(false);
    const msalInstanceRef = useRef(null);
    const accessTokenRef = useRef(null);
    const refreshTokenRef = useRef(null);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [role, setRole] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    // Initialize MSAL
    useEffect(() => {
        const initializeMsal = async () => {
            const msal = new PublicClientApplication(msalConfig);
            await msal.initialize();
            msalInstanceRef.current = msal;
            setMsalInitialized(true);
        };

        initializeMsal();
    }, []);

    // Load tokens from storage
    useEffect(() => {
        const checkStoredTokens = async () => {
            const storedAccessToken = localStorage.getItem('accessToken');
            const storedRefreshToken = localStorage.getItem('refreshToken');

            if (storedAccessToken && storedRefreshToken && msalInstanceRef.current && msalInitialized) {
                const accounts = msalInstanceRef.current.getAllAccounts();
                if (accounts.length > 0) {
                    setAccessToken(storedAccessToken);
                    setIsAuthenticatedUser(true);
                    setUserInfo(accounts[0]);
                }
            }
        };

        checkStoredTokens();
    }, [msalInitialized]);

    useEffect(() => {
        const checkUser = async () => {
            if (userInfo) {
                const userExists = await checkUserExist(userInfo.username);
                if (!userExists) {
                    const userData = {
                        email: userInfo.username,
                        role: 'Sender',
                        name: userInfo.name,
                    };
                    await insertUser(userData);
                    setRole('Sender');
                } else {
                    const user = await getUser(userInfo.username);
                    setRole(user.role);
                }
            }
        };

        checkUser();
    }, [userInfo]);

    const loginAzure = async () => {
        try {
            if (msalInitialized && msalInstanceRef.current && !isLoggingIn) {
                setIsLoggingIn(true);
                const response = await msalInstanceRef.current.loginPopup({
                    scopes: ["Files.ReadWrite.All", "user.read"], // Request scopes during login
                });
                const accounts = await msalInstanceRef.current.getAllAccounts();
                if (accounts.length > 0) {
                    setUserInfo(accounts[0]);
                    setIsAuthenticatedUser(true);
                    setAccessToken(response.accessToken);
                    localStorage.setItem('accessToken', response.accessToken);
                }
            } else {
                console.error('MSAL instance is not initialized or login is already in progress');
            }
        } catch (error) {
            console.error('Login Error:', error);
        } finally {
            setIsLoggingIn(false);
        }
    };

    // Logout from Azure
    const logout = async () => {
        try {
            await msalInstanceRef.current.logout();
            setAccessToken(null);
            setIsAuthenticatedUser(false);
            setUserInfo(null);
            localStorage.removeItem('accessToken');
        } catch (error) {
            console.error('Logout Error:', error);
        }
    };

    // Refresh token
    const refreshToken = async () => {
        try {
            const accounts = await msalInstanceRef.current.getAllAccounts();
            if (accounts.length === 0) {
                await msalInstanceRef.current.loginPopup();
            }

            const response = await msalInstanceRef.current.acquireTokenSilent({
                scopes: msalConfig.scopes,
                account: accounts[0],
            });

            setAccessToken(response.accessToken);

            localStorage.setItem('accessToken', response.accessToken);
        } catch (error) {
            console.error('Refresh Token Error:', error);
        }
    };

    // Validate and refresh token if needed
    const validateToken = async () => {
        const token = accessTokenRef.current || accessToken;

        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            const tokenExpiresIn = decodedToken.exp - currentTime;
            const bufferTime = 5 * 60; // 5 minutes buffer before the token expires

            // If the token is about to expire in less than 5 minutes, refresh it
            if (tokenExpiresIn < bufferTime) {
                await refreshToken();
            }
        }
    };

    // Set an interval to validate and refresh token
    useEffect(() => {
        const intervalId = setInterval(async () => {
            await validateToken();
        }, 60 * 1000); // Run every minute to check token expiration

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [accessToken]);

    return (
        <AuthContext.Provider
            value={{
                user: userInfo,
                login: loginAzure,
                logout,
                isAuthenticated: isAuthenticatedUser,
                accessToken: accessTokenRef.current || accessToken,
                refreshToken,
                setAccessToken,
                role,
                validateToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

import React from 'react';

const AdjustableTextarea = ({ value = '', onChange, disabled }) => {
    return (
        <textarea
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={{
                resize: 'both', // Allow both vertical and horizontal resizing
                minHeight: '100px', // Set minimum height
                minWidth: '300px', // Set minimum width
                padding: '8px', // Add padding
                border: '1px solid #ccc', // Add border
                borderRadius: '5px', // Add border radius
                fontSize: '1em', // Set font size
                maxHeight: '400px', // Set maximum height
                maxWidth: '400px', // Set maximum width
            }}
        />
    );
};

export default AdjustableTextarea;

import React, { useState, useEffect } from 'react';
import { getPdf } from '../../api/PdfUpload';
import { pdfjs } from 'react-pdf';
import { Spin, Modal } from 'antd';
import { useAuth } from '../../context/AuthContext';
import { getPdfFromAzure } from '../../api/PdfUpload';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = React.memo(({ accessToken, fileName, status, company }) => {
    const [pdfContent, setPdfContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const { refreshToken } = useAuth();

    useEffect(() => {
        setLoading(true);
        const loadPdf = async () => {
            try {
                const pdf = await getPdf(accessToken, fileName, status, company);
                if (pdf) {
                    setPdfContent(pdf);
                }
                else {
                    const refreshTokenResponse = await refreshToken();
                    if (refreshTokenResponse) {
                        const pdf = await getPdf(refreshTokenResponse.accessToken, fileName, status, company);
                        setPdfContent(pdf);
                    }

                }
            }
            catch (error) {
                await getPdfFromAzure(fileName).then((response) => {
                    setPdfContent(response);
                }
                ).catch((error) => {
                    showAlert('error', 'Error while fetching pdf');
                });
            }
        };

        loadPdf();
        setLoading(false);
    }, [accessToken, fileName, status, company]);

    const showAlert = (type, message) => {
        Modal[type]({
            title: 'Alert',
            content: message,
        });
    };

    const styles = `
    .pdf-document {
        height: calc(100vh - 100px); /* Adjust the height based on your requirement */
        overflow: auto; /* Enable scrolling if needed */
        width: 100%;
    }
`;

    if (loading) {
        return <Spin
            size="large"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flex: 1, marginTop: -100 }}
        />;
    }

    return (
        <div>
            <style>{styles}</style>
            {pdfContent && (
                <embed src={URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))} width="200%" height="500" />
            )}
        </div>
    );
});

export default PdfViewer;

import React, { useState } from 'react';
import { DatePicker, Input, Select, Modal } from 'antd';
import moment from 'moment';

const { Option } = Select;

const InvoiceForm = ({
    company,
    vendor,
    vendorOptions,
    companyOptions,
    invoiceNumber,
    invoiceDate,
    dueDate,
    currency,
    currencyOptions,
    amount,
    handleVendorChange,
    handleCompanyChange,
    handleInvoiceNumberChange,
    handleInvoiceDateChange,
    handleDueDateChange,
    handleCurrencyChange,
    handleAmountChange,
    handleSubmit,
    addNewVendor,
    marginLeft,
    buttonText = 'Next',
}) => {
    const [newVendor, setNewVendor] = useState('');
    const [modalVisible, setModalVisible] = useState(false);

    const styles = `
    .invoice-container {
        padding: 20px;
        margin-left: ${marginLeft || '320px'};
    }
    .input-group {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }
    .input-group label {
        min-width: 120px; /* Set a minimum width for labels */
        margin-right: 20px;
        margin-top: 5px;
    }
    .input-group input {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        flex: 1; /* Fill remaining space */
        font-size: 1em;
        height: 20px;
        background-color: #fff;
    }
    .submit-button {
        background-color: #007bff;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        margin-top: 20px;
        width: 60%;
        margin-left: 20%;
    }
    .submit-button:hover {
        background-color: #0056b3;
    }
    .submit-button:active {
        background-color: #0056b3;
    }
    .h1 {
        font-size: 1em;
        margin-bottom: 20px;
        text-align: center;
    }
    .add-vendor {
        background-color: #007bff;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        width: 40%;
    }
    .add-vendor:hover {
        background-color: #0056b3;
    }
`;

    const datePickerStyle = {
        width: "100%",
        height: "40px",
        fontSize: "1.2em",
        borderRadius: "5px",
        border: "1px solid #ccc",
        padding: "10px",
        boxSizing: "border-box"
    };

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    };

    const handleNewVendorChange = () => {
        setModalVisible(true);
    };

    return (
        <div className="invoice-container">
            <style>{styles}</style>
            <h1 className="h1">Invoice Details</h1>
            <form onSubmit={handleSubmit}>
            <div className="input-group">
                    <label htmlFor="company">Company</label>
                    <Select
                        id="company"
                        showSearch  // Enable search functionality
                        value={company}
                        onChange={handleCompanyChange}
                        style={{ width: 250, height: 40, fontSize: '1.2em', backgroundColor: '#e1eaef' }}
                        placeholder="Select Company"
                        optionFilterProp="children"  // Specify the property to filter options on
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }  // Filter options based on input
                        required
                    >
                        {companyOptions?.map((option) => (
                            <Option key={option.id} value={option.company}>
                                {option.company}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="input-group">
                    <label htmlFor="vendor">Vendor</label>
                    <Select
                        id="vendor"
                        showSearch  // Enable search functionality
                        value={vendor}
                        onChange={handleVendorChange}
                        style={{ width: 250, height: 40, fontSize: '1.2em', backgroundColor: '#e1eaef' }}
                        placeholder="Select Vendor"
                        optionFilterProp="children"  // Specify the property to filter options on
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }  // Filter options based on input
                        required
                    >
                        {vendorOptions.map((option) => (
                            <Option key={option.id} value={option.vendor}>
                                {option.vendor}
                            </Option>
                        ))}
                    </Select>
                    <button type="button" className="add-vendor" onClick={handleNewVendorChange}>Add New Vendor</button>
                </div>
                <Modal
                    title="Add New Vendor"
                    open={modalVisible}
                    onOk={() => addNewVendor(newVendor)}
                    onCancel={() => setModalVisible(false)}
                >
                    <Input
                        placeholder="Enter new vendor name"
                        value={newVendor}
                        onChange={(e) => setNewVendor(e.target.value)}
                    />
                </Modal>
                <div className="input-group">
                    <label htmlFor="invoice-number">Invoice Number</label>
                    <input
                        type="text"
                        id="type"
                        value={invoiceNumber}
                        onChange={handleInvoiceNumberChange}
                        required
                        disabled={invoiceNumber !== ''}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="invoice-date">Invoice Date</label>
                    <DatePicker
                        id="invoice-date"
                        value={invoiceDate}
                        onChange={handleInvoiceDateChange}
                        required
                        format="DD/MM/YYYY"
                        style={datePickerStyle}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="due-date">Due Date</label>
                    <DatePicker
                        id="due-date"
                        value={dueDate}
                        onChange={handleDueDateChange}
                        format="DD/MM/YYYY"
                        style={datePickerStyle}
                        disabledDate={disabledDate}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="currency">Currency</label>
                    <Select
                        id="currency"
                        showSearch  // Enable search functionality
                        value={currency}
                        onChange={handleCurrencyChange}
                        style={{ width: 410, height: 40, fontSize: '1.2em', backgroundColor: '#e1eaef' }}
                        placeholder="Select Currency"
                        optionFilterProp="children"  // Specify the property to filter options on
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }  // Filter options based on input
                        required
                    >
                        {currencyOptions.map((option) => (
                            <Option key={option} value={option}>
                                {option}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="input-group">
                    <label htmlFor="amount">Amount</label>
                    <input
                        type="text"
                        id="amount"
                        value={amount}
                        onChange={handleAmountChange}
                        required
                    />
                </div>
                <button type="submit" className="submit-button">{buttonText}</button>
            </form>
        </div>
    );
};

export default InvoiceForm;

import React from 'react';

const VendorForm = ({
    vendor,
    type,
    recurring,
    handleVendorChange,
    handleTypeChange,
    handleRecurringChange,
    handleSubmit,
    isUpdate
}) => {

    const styles = `
    .vendor-container {
        padding: 20px;
        margin-top: 20px;
    }
    .h1 {
        margin-bottom: 20px;
    }
    .input-group {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }
    .input-group label {
        min-width: 80px; /* Set a minimum width for labels */
        margin-right: 20px;
        margin-top: 5px;
    }
    .input-group input {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        flex: 1; /* Fill remaining space */
        font-size: 1em;
        height: 20px;
        width: 60%;
        background-color: #fff;
    }
    .submit-button {
        background-color: #007bff;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        margin-top: 20px;
        width: 30%;
        margin-left: 30%;
    }
    .input-group input[type="checkbox"] {
        width: 20px;
        height: 20px;
    }
    .submit-button:hover {
        background-color: #0056b3;
    }
    .submit-button:active {
        background-color: #0056b3;
    }
    .input-group input[id="type"] {
        width: 20%;
    }
    .input-group-small {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }
    .input-group-small label {
        min-width: 80px; /* Set a minimum width for labels */
        margin-right: 20px;
        margin-top: 5px;
    }
    .input-group-small input {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        flex: 1; /* Fill remaining space */
        font-size: 1em;
        height: 20px;
        width: 30%;
        background-color: #fff;
    }
`;

    return (
        <div className="vendor-container">
            <style>{styles}</style>
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <label htmlFor="vendor">Vendor Name</label>
                    <input
                        type="text"
                        id="vendor"
                        value={vendor}
                        onChange={handleVendorChange}
                        required
                    />
                </div>
                <div className="input-group-small">
                    <label htmlFor="type">Type</label>
                    <input
                        type="text"
                        id="type"
                        value={type}
                        onChange={handleTypeChange}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="recurring">Recurring</label>
                    <input
                        type="checkbox"
                        id="recurring"
                        checked={recurring}
                        onChange={handleRecurringChange}
                    />
                </div>
                <button type="submit" className="submit-button">{isUpdate ? 'Update' : 'Create'}</button>
            </form>
        </div>
    );
};

export default VendorForm;

import React, { useState, useEffect } from 'react';
import { Spin, message } from 'antd';
import UserList from '../molecules/UserList';
import { updateUser } from '../../api/UsersContainer';
import { useAuth } from '../../context/AuthContext';
import { insertFirstAppover, checkFirstApproverExist, deleteFirstApprover } from '../../api/firstApproversContainer';

const User = () => {
    const [loading, setLoading] = useState(false);
    const { role, user } = useAuth();

    const styles = `
    .user-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-top: 50px;
    }
    .user-list-container {
        flex: 1; /* Take up remaining space vertically */
        margin-top: -20px;
        margin-left: -20px;
        width: 100%;
    }
    .h1 {
        margin-left: 20px;
        margin-top: 20px;
        align-self: center;
        justify-self: center;
    }
`;

    const handleRoleChange = async (id, role, name, email) => {
        setLoading(true);
        await updateUser(id, role);

        if (role === 'First Approver') {
            const exist = await checkFirstApproverExist(email);

            if (!exist) {
                await insertFirstAppover({ email: email, controller: name });
            }

        }
        else {
            const exist = await checkFirstApproverExist(email);

            if (exist) {
                await deleteFirstApprover(email);
            }
        }

        message.success('User role updated successfully');
        setLoading(false);
    }


    if (loading) {
        return <Spin
            size="large"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flex: 1, marginTop: -100 }}
        />;
    }

    return (
        <div className='user-container'>
            <style>{styles}</style>
            {loading && <Spin size='large' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flex: 1, marginTop: -100 }} />}
            {role === 'Admin' && (
                <div className='user-list-container'>
                    <UserList handleRoleChange={handleRoleChange} />
                </div>
            )}
        </div>
    );
};

export default User;

import axios from 'axios';
import { folderVariables, folderVariablesSodaWorld } from './oneDriveConfig';
import { BlobServiceClient } from '@azure/storage-blob';

export const uploadInvoicePdf = async (pdfFile, accessToken, refreshToken, onedrive_folder, setAccessToken) => {
    if (!pdfFile) {
        throw new Error('PDF file is required');
    }

    const onedriveFilename = pdfFile.name;
    const url = `https://graph.microsoft.com/v1.0/users/document@wesoda.co.uk/drive/root:/${onedrive_folder}/${onedriveFilename}:/content`;

    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/pdf',
    };

    try {
        const response = await axios.put(url, pdfFile, { headers });
        return response.data;
    } catch (error) {
    }

};

export const moveFileFromOneDrive = async (accessToken, filename, company) => {
    //console.log('accessToken', accessToken);
    try {
        const companyFolder = company === 'We Soda Ltd' 
            ? folderVariables.invoiceFolder
            : company === 'Soda World Ltd' 
                ? folderVariablesSodaWorld.invoiceFolder
                : folderVariables.invoiceFolder;

        const url = `https://graph.microsoft.com/v1.0/users/document@wesoda.co.uk/drive/root:/${companyFolder}/${filename}:/content`;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        let fileContent;
        
        try {
            // Try to get the file from OneDrive
            const response = await axios.get(url, { headers, responseType: 'arraybuffer' });
            fileContent = response.data;
        } catch (oneDriveError) {
            console.error('File not found in OneDrive, trying Azure Storage:', oneDriveError?.response?.data || oneDriveError.message);

            // If the file is not found in OneDrive, try to fetch it from Azure Storage
            try {
                fileContent = await getPdfFromAzure(filename);
            } catch (azureError) {
                console.error('Error fetching file from Azure Storage:', azureError?.response?.data || azureError.message);
                throw new Error('Failed to retrieve file from both OneDrive and Azure Storage');
            }
        }

        // Move the file to the approval folder on OneDrive (if file was successfully retrieved)
        const new_folder = company === 'We Soda Ltd' 
            ? folderVariables.invoiceApprovalFolder
            : company === 'Soda World Ltd' 
                ? folderVariablesSodaWorld.invoiceApprovalFolder
                : folderVariables.invoiceApprovalFolder;

        const new_url = `https://graph.microsoft.com/v1.0/users/document@wesoda.co.uk/drive/root:/${new_folder}/${filename}:/content`;

        await axios.put(new_url, fileContent, { headers });

        //deletePdfFromOneDrive(accessToken, onedrive_folder, filename);
        //deletePdfNode(accessToken, filename);
    } catch (error) {
        console.error('Error moving file from OneDrive:', error?.response?.data || error.message);
        throw new Error('Error on file upload: ' + error.response.data.error.message);
    }
};

export const uploadAzureBlob = async (file) => {
    console.log('file', file);
    try {
        const filename = file.name;
        const blobServiceClient = BlobServiceClient.fromConnectionString(process.env.REACT_APP_AZURE_STORAGE_CONNECTION_STRING);
        const containerClient = blobServiceClient.getContainerClient('invoices');
        const blockBlobClient = containerClient.getBlockBlobClient(filename);

        await blockBlobClient.uploadData(file);
    } catch (error) {
        console.error('Error uploading file to Azure Blob:', error);
    }
}

export const getPdfFromAzure = async (filename) => {
    // Get the PDF file from azure blob storage
    try {
        const blobServiceClient = BlobServiceClient.fromConnectionString(process.env.REACT_APP_AZURE_STORAGE_CONNECTION_STRING);
        const containerClient = blobServiceClient.getContainerClient('invoices');
        const blockBlobClient = containerClient.getBlockBlobClient(filename);

        const downloadBlockBlobResponse = await blockBlobClient.download(0);

        // Use the blobBody property, which is a promise
        const blob = await downloadBlockBlobResponse.blobBody; // Corrected line
        const arrayBuffer = await blob.arrayBuffer(); // Convert blob to ArrayBuffer

        return arrayBuffer; // Return the ArrayBuffer
    } catch (error) {
        console.error('Error getting PDF from Azure Blob:', error);
    }
};

const getFileFromOneDrive = async (accessToken, filename, folder) => {
    try {
        const url = `https://graph.microsoft.com/v1.0/users/document@wesoda.co.uk/drive/root:/${folder}/${filename}:/content`;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            // Attempt to fetch the file from OneDrive
            const response = await axios.get(url, { headers, responseType: 'arraybuffer' });
            return response.data; // Return the file content from OneDrive
        } catch (oneDriveError) {
            console.error('Error fetching file from OneDrive:', oneDriveError?.response?.data || oneDriveError.message);

            // If OneDrive fails, attempt to fetch from Azure Storage
            try {
                const azureResponse = await getPdfFromAzure(filename);
                return azureResponse; // Return the file content from Azure
            } catch (azureError) {
                console.error('Error fetching file from Azure Storage:', azureError?.response?.data || azureError.message);
                throw new Error('Failed to fetch file from both OneDrive and Azure Storage');
            }
        }
    } catch (error) {
        console.error('Error fetching file from OneDrive:', error.response.data);
    }
};

export const getPdf = async (accessToken, fileName, status, company) => {
    try {
        const companyFolder = company === 'We Soda Ltd' ? folderVariables : company === 'Soda World Ltd' ? folderVariablesSodaWorld : folderVariables
        const folder = status === 'Approved' ? companyFolder.invoiceApprovalFolder : status === 'Rejected' ? companyFolder.invoiceRejectedFolder : status === 'Paid' ? companyFolder.invoicePaidFolder : companyFolder.invoiceFolder;

        const fileContent = await getFileFromOneDrive(accessToken, fileName, folder);

        if (!fileContent) {
            await getPdfFromAzure(fileName).then((response) => {
                return response;
            }
            ).catch((error) => {
                console.error('Error on getPdf fetching file from OneDrive:', error.response.data);
            });
        }

        return fileContent;
    } catch (error) {
        console.error('Error fetching PDF:', error);
    }
};

export const deletePdfFromOneDrive = async (accessToken, folder, fileName) => {
    try {
        await deletePdf(accessToken, folder, fileName);
    } catch (error) {
        console.error('Error deleting PDF:', error);
    }
};

export const getFileIdFromOneDrive = async (accessToken, folder, filename) => {
    try {
        const url = `https://graph.microsoft.com/v1.0/users/document@wesoda.co.uk/drive/root:/${folder}:/children?$filter=name eq '${filename}'`;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        const response = await axios.get(url, { headers });
        const fileId = response.data.value[0].id; // Extract the ID of the first file with the specified name
        return fileId;
    } catch (error) {
        console.error('Error fetching file ID from OneDrive:', error.response.data);
    }
};

const deletePdf = async (accessToken, folder, fileName) => {
    try {
        // const fileId = await getFileIdFromOneDrive(accessToken, folder, fileName);

        // if (!fileId) {
        //     console.error(`File '${fileName}' not found in folder '${folder}'.`);
        //     return;
        // }

        const deleteUrl = `https://graph.microsoft.com/v1.0/users/document@wesoda.co.uk/drive/root:/${folder}/${fileName}`;

        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        await axios.delete(deleteUrl, { headers });
        //console.log(`File '${fileName}' deleted successfully.`);
    } catch (error) {
        console.error('Error deleting PDF:', error);
    }
};

export const moveFileToRejectedFolder = async (accessToken, filename, company, status) => {
    try {
        const companyFolder = company === 'We Soda Ltd' ? folderVariables : company === 'Soda World Ltd' ? folderVariablesSodaWorld : folderVariables;
        const folder = status === 'Approved' ? companyFolder.invoiceApprovalFolder : companyFolder.invoiceFolder;
        const url = `https://graph.microsoft.com/v1.0/users/document@wesoda.co.uk/drive/root:/${folder}/${filename}:/content`;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        const response = await axios.get(url, { headers, responseType: 'arraybuffer' });

        // Move the file to another folder
        const new_folder = companyFolder.invoiceRejectedFolder; // Adjust the folder name as per your requirement
        const new_url = `https://graph.microsoft.com/v1.0/users/document@wesoda.co.uk/drive/root:/${new_folder}/${filename}:/content`;

        await axios.put(new_url, response.data, { headers });

    } catch (error) {
        console.error('Error fetching file from OneDrive:', error.response.data);
    }
}

export const moveFileToPaidFolder = async (accessToken, filename, company, status) => {
    try {
        const companyFolder = company === 'We Soda Ltd' ? folderVariables : company === 'Soda World Ltd' ? folderVariablesSodaWorld : folderVariables;
        const folder = status === 'Approved' ? companyFolder.invoiceApprovalFolder : companyFolder.invoiceFolder;
        const url = `https://graph.microsoft.com/v1.0/users/document@wesoda.co.uk/drive/root:/${folder}/${filename}:/content`;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        let fileContent;

        try {
            // Try to get the file from OneDrive
            const response = await axios.get(url, { headers, responseType: 'arraybuffer' });
            fileContent = response.data;
        } catch (oneDriveError) {
            console.error('File not found in OneDrive, trying Azure Storage:', oneDriveError?.response?.data || oneDriveError.message);

            // If the file is not found in OneDrive, try to fetch it from Azure Storage
            try {
                fileContent = await getPdfFromAzure(filename);
            } catch (azureError) {
                console.error('Error fetching file from Azure Storage:', azureError?.response?.data || azureError.message);
                throw new Error('Failed to retrieve file from both OneDrive and Azure Storage');
            }
        }

        // Move the file to the "Paid" folder on OneDrive (if file was successfully retrieved)
        
        const new_folder = companyFolder.invoicePaidFolder; // Adjust the folder name as per your requirement
        const new_url = `https://graph.microsoft.com/v1.0/users/document@wesoda.co.uk/drive/root:/${new_folder}/${filename}:/content`;

        await axios.put(new_url, fileContent, { headers });

    } catch (error) {
        console.error('Error moving file to Paid folder:', error?.response?.data || error.message);
    }
}

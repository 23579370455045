import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './ui/pages/Layout';
import Invoice from './ui/pages/Invoice';
import './Global.css'; // Import your global CSS file
import InvoiceHistory from './ui/pages/InvoiceHistory';
import Vendor from './ui/pages/Vendor';
import InvoiceDetail from './ui/pages/InvoiceDetail';
import Login from './ui/pages/Login';
import { useAuth } from './context/AuthContext'; // Import useAuth hook
import User from './ui/pages/User';

function App() {
  const { role, refreshToken } = useAuth();
  const authCtx = useAuth(); // Using useAuth hook to get context
  const isAuthenticated = authCtx.isAuthenticated;
  // console.log('isAuthenticated', isAuthenticated);

  return (
    <Router>
      {isAuthenticated ? (
        <Layout>
          <Routes>
            {role == ! 'Financial Controller' &&
              <Route path="/" element={<Invoice />} />
            }
            {role == 'Financial Controller' &&
              <Route path="/" element={<InvoiceHistory />} />
            }
            <Route path="/" element={<Invoice />} />
            <Route path="/invoice-history" element={<InvoiceHistory />} />
            <Route path="/invoice-detail/:id" element={<InvoiceDetail />} />
            <Route path="/vendors" element={<Vendor />} />
            <Route path="/users" element={<User />} />
          </Routes>
        </Layout>
      ) : (
        <Login /> // Render Login component when user is not authenticated
      )}
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Form, Button, message, Spin } from 'antd';
import { useAuth } from '../../context/AuthContext';
import logo from '../../logo.jpg';

const Login = () => {
    const { login } = useAuth();
    const [loading, setLoading] = useState(false);

    const handleLogin = async (values) => {
        try {
            setLoading(true);
            await login(values.email, values.password);
            setLoading(false);
        } catch (error) {
            message.error('Invalid email or password');
        }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', marginTop: -60, backgroundColor: '#201658' }}>
                <div style={{ width: 350, backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <img src={logo} alt="Logo" style={{ width: '200px' }} />
                    </div>
                    <Spin
                        size="large"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}
                    />
                </div>
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', marginTop: -60, backgroundColor: '#201658' }}>
            <div style={{ width: 350, backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <img src={logo} alt="Logo" style={{ width: '200px' }} />
                </div>
                {/* <Title level={2} style={{ textAlign: 'center' }}>Login</Title> */}
                <Form name="normal_login" onFinish={handleLogin}>
                    {/* <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: 'Please input your email!' },
                            { type: 'email', message: 'The input is not a valid email!' }
                        ]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                    </Form.Item> */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;

import { CosmosClient } from '@azure/cosmos';

// Azure Cosmos DB configuration
const endpoint = process.env.REACT_APP_COSMOS_DB_URI;
const key = process.env.REACT_APP_COSMOS_DB_KEY;
const databaseId = process.env.REACT_APP_COSMOS_DB_ID;
const containerId = 'vendors';

const client = new CosmosClient({ endpoint, key });

const database = client.database(databaseId);
const container = database.container(containerId);

// Function to insert vendor data into Cosmos DB
const insertVendor = async (vendorData) => {
    try {
        const { resource } = await container.items.create(vendorData);
        return resource;
    } catch (error) {
        console.error('Error inserting vendor data:', error);
        throw error;
    }
};

// Function to get all vendors from Cosmos DB
const getVendors = async () => {
    try {
        const { resources } = await container.items.readAll().fetchAll();
        return resources;
    } catch (error) {
        console.error('Error getting vendors:', error);
        throw error;
    }
};

const updateVendor = async (id, vendor, type, recurring) => {
    try {
        const { resource } = await container.items.upsert({ id, vendor, type, recurring });
        return resource;
    } catch (error) {
        console.error('Error updating vendor data:', error);
        throw error;
    }
};

const getCompanies = async () => {
    // return hardocded companies for now
    return [
        { id: '1', company: 'We Soda Ltd' },
        { id: '2', company: 'Soda World Ltd' },
    ];
};

const getFinancialControllers = async () => {
    // return hardocded companies for now
    return [
        { id: '1', controller: 'Jim Yow (Financial Controller)', email: 'jim.yow@wesoda.com', financialController: true },
        { id: '2', controller: 'Ahmet Tohma', email: 'ahmet.tohma@wesoda.com' },
        { id: '3', controller: 'Alan Knight', email: 'alan.knight@wesoda.com' },
        { id: '4', controller: 'Alasdair Warren', email: 'alasdair.warren@wesoda.com' },
        { id: '5', controller: 'Ali Hasan Bekce', email: 'ali.bekce@wesoda.com' },
        { id: '6', controller: 'Anita Siddle', email: 'anita.siddle@wesoda.com' },
        { id: '7', controller: 'Christine Webb', email: 'christine.webb@wesoda.com' },
        { id: '8', controller: 'Edward Westropp', email: 'edward.westropp@wesoda.com' },
        { id: '9', controller: 'Enver Citkin', email: 'enver.citkin@wesoda.com' },
        { id: '10', controller: 'CG Finance Approvals', email: 'finance.approvals@cinerglass.com' },
        { id: '11', controller: 'Henry Penn', email: 'henry.penn@wesoda.com' },
        { id: '12', controller: 'Jeremy Small', email: 'jeremy.small@wesoda.com' },
        { id: '13', controller: 'Mehmet Erdogan', email: 'mehmet.erdogan@wesoda.com' },
        { id: '14', controller: 'Natalia Buynova', email: 'n.buynova@wesoda.com' },
        { id: '15', controller: 'Nicholas Hall', email: 'nicholas.hall@wesoda.com' },
        { id: '16', controller: 'Nilay Akgerman', email: 'nilay.akgerman@wesoda.com' },
        { id: '17', controller: 'Omer Ercengiz', email: 'omer.ercengiz@wesoda.com' },
        { id: '18', controller: 'Reyhan Kaya', email: 'reyhan.kaya@wesoda.com' },
        { id: '19', controller: 'Sevgul Erdogan', email: 'sevgul.erdogan@wesoda.com' },
        { id: '20', controller: 'Sinan Mustafa Ozgurel', email: 'sinan.ozgurel@wesoda.com' },
        { id: '21', controller: 'William Chaplin', email: 'william.chaplin@wesoda.com' },
        { id: '22', controller: 'Dogukan Karaca', email: 'dogukan.karaca@wesoda.com' },
        { id: '23', controller: 'Emrah Aydin', email: 'emrah.aydin@wesoda.com' },
        { id: '24', controller: 'Cuneyt Basaran', email: 'cuneyt.basaran@wesoda.com' },
        { id: '25', controller: 'Melike Tekdag', email: 'melike.tekdag@wesoda.com' },
        { id: '26', controller: 'Iryna Shevchuk', email: 'iryna.shevchuk@wesoda.com' },
    ];
}

export { insertVendor, getVendors, updateVendor, getCompanies, getFinancialControllers };

import React, { useEffect, useState } from 'react';
import { GlobalWorkerOptions, getDocument, PDFDocumentProxy } from 'pdfjs-dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import InvoiceForm from '../molecules/InvoiceForm';
import StepIndicator from '../molecules/StepIndicator';
import PDFPreview from '../molecules/PDFPreview';
import SubmitForm from '../molecules/SubmitForm';
import dayjs from 'dayjs';
import { Spin, Modal } from 'antd';
import { pdfjs } from 'react-pdf';
import moment from 'moment';
import { getVendors, insertVendor, getCompanies, getFinancialControllers } from '../../api/VendorsContainer';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook from React Router
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useAuth } from '../../context/AuthContext';
import { uploadInvoicePdf, uploadAzureBlob } from '../../api/PdfUpload';
import { insertInvoice, checkInvoiceExists } from '../../api/InvoiceContainer';
import { sendEmail } from '../../api/NodeServer';
import { folderVariables, folderVariablesSodaWorld } from '../../api/oneDriveConfig';
import FinancialApproval from '../molecules/FinancialApproval';
import { extractInvoice } from '../../api/AiInvoiceParser';
import { PDFDocument } from 'pdf-lib';
import { getFirstApprovers } from '../../api/firstApproversContainer';

//GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.1.392/pdf.worker.min.mjs`;

const Invoice = () => {
    const [loading, setLoading] = useState(false);
    const [vendor, setVendor] = useState('');
    const [vendors, setVendors] = useState([]);
    const [company, setCompany] = useState('');
    const [companies, setCompanies] = useState([]);
    const [controllers, setControllers] = useState([]);
    const [financialController, setFinancialController] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceDate, setInvoiceDate] = useState(dayjs());
    const [dueDate, setDueDate] = useState('');
    const [currency, setCurrency] = useState('');
    const [amount, setAmount] = useState('');
    const [activeTab, setActiveTab] = useState('upload');
    const [file, setFile] = useState(null);
    const [base64Jpg, setBase64Jpg] = useState(null);
    const [pdfContent, setPdfContent] = useState(null);
    const [comments, setComments] = useState('');
    const navigate = useNavigate();
    const { user, accessToken, refreshToken, setAccessToken, role, validateToken } = useAuth();
    const [sender, setSender] = useState(user?.name);
    const [errorMessages, setErrorMessages] = useState([]);

    // useEffect(() => {
    //     const checkTokenValidity = async () => {
    //         try {
    //             // Validate the current token
    //             const response = await validateToken();

    //             setAccessToken(response.accessToken);
    //         } catch (error) {
    //             console.error('Error validating token:', error);
    //         }
    //     };

    //     checkTokenValidity();

    //     // You can add more dependencies if needed, e.g., accessTokenRef.current, refreshTokenRef.current
    // }, []);


    useEffect(() => {
        const fetchData = async () => {
            const data = await getVendors();
            setVendors(data);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getCompanies();
            setCompanies(data);

            const controllersData = await getFinancialControllers();
            //const controllersData = await getFirstApprovers();
            setControllers(controllersData);

            setFinancialController(controllersData[0].controller);
        };

        fetchData();
    }, []);

    const goBack = () => {
        setActiveTab('upload');
        setFile(null);
        setAmount('');
        setCurrency('');
        setDueDate('');
        setInvoiceDate('');
        setInvoiceNumber('');
        setVendor('');
    };

    const goBackSubmit = () => {
        setActiveTab('upload');
    };

    const handleVendorChange = (newVendor) => {
        setVendor(newVendor);
    };

    const handleCompanyChange = (newCompany) => {
        setCompany(newCompany);
    };

    const handleInvoiceNumberChange = (e) => {
        setInvoiceNumber(e.target.value);
    };

    const handleInvoiceDateChange = (dateString) => {
        if (dateString === null) {
            return;
        }
        setInvoiceDate(dayjs(dateString));
    };

    const handleDueDateChange = (dateString) => {
        if (dateString === null) {
            return;
        }
        setDueDate(dayjs(dateString));
    };

    const handleCurrencyChange = (value) => {
        setCurrency(value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const showAlert = (type, message) => {
        Modal[type]({
            title: 'Alert',
            content: message,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (vendor === '') {
            showAlert('error', 'Please select a vendor');
            return;
        }

        if (company === '') {
            showAlert('error', 'Please select a company before proceeding');
            return;
        }

        if (currency === '') {
            showAlert('error', 'Please select a currency');
            return;
        }

        try {
            const vendor_id = vendors.find(v => v.vendor === vendor).id;
            const response = await checkInvoiceExists(invoiceNumber, vendor_id);

            if (response) {
                //alert('Invoice already exists');
                showAlert('error', 'Invoice already exists');
            } else {
                setActiveTab('submit');
            }
        } catch (error) {
        }
    };

    const handleNewVendor = async (newVendor) => {
        setLoading(true);
        try {
            const vendorData = {
                vendor: newVendor,
                recurring: false
            }
            const insertedVendor = await insertVendor({ vendor: newVendor, type: null, recurring: false });
            const newVendorData = { id: insertedVendor.id, vendor: newVendor, recurring: false };
            setVendors([...vendors, newVendorData]);
            setVendor(newVendor);
        } catch (error) {
            // Handle error, show error message, etc.
        }
        setLoading(false);
    };

    const readFileAsArrayBuffer = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    useEffect(() => {
        const loadFileData = async () => {
            if (file) {
                try {
                    const fileData = await readFileAsArrayBuffer(file);
                    setPdfContent(fileData);
                    //parsePDFInvoice(file);
                    const fileReader = new FileReader();
                    fileReader.onload = async (e) => {
                        const typedArray = new Uint8Array(e.target.result);
                        const pdf = await getDocument(typedArray).promise;
                        const page = await pdf.getPage(1); // Extract the first page

                        const viewport = page.getViewport({ scale: 1.5 });
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.width = viewport.width;
                        canvas.height = viewport.height;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };

                        await page.render(renderContext).promise;
                        const dataUrl = canvas.toDataURL('image/jpeg'); // Convert canvas to JPEG Base64
                        const base64String = dataUrl.split(',')[1];
                        setBase64Jpg(base64String);
                    };
                    fileReader.readAsArrayBuffer(file);
                } catch (error) {
                }
            }
        };

        loadFileData();
    }, [file]);

    useEffect(() => {
        if (base64Jpg) {
            setLoading(true);
            const parseInvoice = async () => {
                try {
                    const extractedData = await extractInvoice(base64Jpg);
                    //console.log('Extracted data:', extractedData);

                    try {
                        // if the extracted data contain backticks ("```") or "json" then remove the backticks and parse the data
                        if (extractedData.includes('```') || extractedData.includes('json')) {
                            extractedData = extractedData.replace(/`/g, '');
                            // remove the json text from the extracted data
                            extractedData = extractedData.replace('json', '');
                            //console.log('Extracted cleaned data:', extractedData);
                        }
                    }
                    catch (error) {
                        setLoading(false);

                    }

                    console.log('Extracted data:', extractedData);

                    findBestMatchVendor(extractedData.companyName, vendors);

                    setInvoiceNumber(extractedData.invoiceNumber);
                    if (extractedData.invoiceDate !== 'not_found') {
                        const date = moment(extractedData.invoiceDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
                        setInvoiceDate(dayjs(date));
                    }
                    if (extractedData.invoiceDueDate !== 'not_found') {
                        const dueDate = moment(extractedData.invoiceDueDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
                        setDueDate(dayjs(dueDate));
                    }
                    setCurrency(extractedData.invoiceCurrency);
                    // Normalize the invoice total
                    let invoiceTotal = extractedData.invoiceTotal;

                    if (invoiceTotal.includes(',')) {
                        // Case: "1.234,56" or "1,234.56"
                        const parts = invoiceTotal.split(',');

                        if (parts.length > 1 && parts[parts.length - 1].length === 2) {
                            // If the last part has 2 digits, it indicates a decimal number
                            invoiceTotal = invoiceTotal.replace(/\./g, '').replace(',', '.');
                        } else {
                            // Otherwise, assume commas are thousand separators
                            invoiceTotal = invoiceTotal.replace(/,/g, '');
                        }
                    } else if (invoiceTotal.includes('.')) {
                        // Case: "1,234.56" or "1.234.56"
                        const parts = invoiceTotal.split('.');

                        if (parts.length > 1 && parts[parts.length - 1].length === 2) {
                            // If the last part has 2 digits, it indicates a decimal number
                            // We assume the format is correct and do nothing
                        } else {
                            // Otherwise, assume periods are thousand separators and remove them
                            invoiceTotal = invoiceTotal.replace(/\./g, '');
                        }
                    }

                    setAmount(invoiceTotal);

                    setLoading(false);
                } catch (error) {
                    console.error('Error parsing PDF:', error);
                    setLoading(false);
                }
            };

            parseInvoice();
        }
    }, [base64Jpg]);

    const findBestMatchVendor = async (extractedCompanyName, vendors) => {
        const foundedVendor = vendors.find(v => v.vendor === extractedCompanyName);

        if (foundedVendor === undefined) {
            handleNewVendor(extractedCompanyName);
        }
        else {
            const existingVendor = vendors.find(v => v.vendor === extractedCompanyName);
            setVendor(existingVendor.vendor);
        }
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        let formattedAmount = amount.replace(/[^0-9.]/g, '');
        formattedAmount = parseFloat(formattedAmount).toFixed(2);
        formattedAmount = formattedAmount.replace(/\.00$/, '');

        const vendorData = vendors.find(v => v.vendor === vendor);
        const formData = {
            company: company,
            vendor_id: vendorData.id,
            invoice_number: invoiceNumber,
            invoice_date: invoiceDate,
            invoice_type: vendorData.type,
            due_date: dueDate,
            currency,
            amount: formattedAmount,
            status: vendorData.recurring ? 'Approved' : financialController === 'Jim Yow (Financial Controller)' ? 'Pending' : 'Pending First Approval',
            sender: sender,
            sender_email: user?.username,
            sender_comment: comments,
            sender_date: new Date(),
            approved_by: vendorData.recurring ? 'System' : null,
            approval_comment: vendorData.recurring ? 'Automatically approved by the system due to recurring invoice' : null,
            approved_date: vendorData.recurring ? new Date() : null,
            file_name: file.name,
            first_approver: financialController === 'Jim Yow (Financial Controller)' ? '' : financialController,
        };

        const onedrive_folder = vendorData.recurring ? folderVariables.invoiceApprovalFolder : folderVariables.invoiceFolder;
        const companyFolder = company === 'We Soda Ltd' ? folderVariables.invoiceFolder : company === 'Soda World Ltd' ? folderVariablesSodaWorld.invoiceFolder : folderVariables.invoiceApprovalFolder;

        try {
            const invoiceId = await insertInvoiceData(formData);

            // Handle errors appropriately within insertInvoiceData function
            if (errorMessages.length > 0) {
                alert('Error inserting invoice');
                setLoading(false);
                return;
            }

            await uploadInvoicePdf(file, accessToken, refreshToken, companyFolder, setAccessToken, validateToken);

            await uploadAzureBlob(file);

            const financialControllerEmail = controllers.find(controller => controller.controller === financialController).email;
            await sendEmail(invoiceId, financialControllerEmail, user?.name, vendor, user?.username);

            setLoading(false);
            navigate('/invoice-history');
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form');
            setLoading(false);
        }
    }

    const insertInvoiceData = async (invoice) => {
        setLoading(true);
        try {
            const invoiceResponse = await insertInvoice(invoice);
            return invoiceResponse.id;
        } catch (error) {
            setErrorMessages([...errorMessages, 'Error inserting invoice']);
            // Handle error, show error message, etc.
        }
        setLoading(false);
    }

    const handleFinancialControllerChange = (value) => {
        setFinancialController(value);
    }

    const styles = `
    .h1 {
        font-size: 2em;
        margin-bottom: 20px;
    }
    .file-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin-left: -20px;
        margin-top: 50px;
        background-color: #EAFFE1;
        border-radius: 10px;
    }
    .file-container label {
        margin-bottom: 20px;
    }
    .file-container input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 10px;
        font-size: 1em;
        font-weight: bold;
        font-family: 'Arial';
    }
    .file-button {
        background-color: #4CAF50;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        cursor: pointer;
        border-radius: 5px;
    }
    .file-button:hover {
        background-color: #45a049;
    }
    .back-button-container {
        margin-top: 10px;
        margin-left: -70px;
    }
    .back-button-container button {
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        background-color: #fff; 
        font-size: 1em;
    }
    .back-button-container button:hover {
        background-color: #f2f2f2;
    }
    .pdf-preview-container {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
    .submit-form-container {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
`;

    if (loading) {
        return <Spin
            size="large"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flex: 1, marginTop: -100 }}
        />;
    }

    return (
        <div>
            <style>{styles}</style>
            <StepIndicator activeTab={activeTab} />
            {activeTab === 'upload' && (
                file === null ? (
                    <div className="file-container">
                        <label htmlFor="file">Please upload the Invoice (select only pdf files)</label>
                        <input
                            type="file"
                            id="file"
                            accept=".pdf"
                            onChange={(e) => setFile(e.target.files[0])}
                            style={{ display: 'none' }}
                        />
                        <button className="file-button" onClick={() => document.getElementById('file').click()}>Add Documents</button>
                    </div>
                ) : (
                    <div>
                        <div className="back-button-container">
                            <button onClick={goBack}>
                                <FontAwesomeIcon icon={faArrowLeft} /> Back
                            </button>
                        </div>
                        <div className='pdf-preview-container'>
                            {file && <PDFPreview pdfFile={file} />}
                            <InvoiceForm
                                company={company}
                                vendor={vendor}
                                vendorOptions={vendors}
                                companyOptions={companies}
                                invoiceNumber={invoiceNumber}
                                invoiceDate={invoiceDate}
                                dueDate={dueDate}
                                currency={currency}
                                currencyOptions={['GBP', 'USD', 'EUR', 'TL', 'TRY']}
                                amount={amount}
                                handleVendorChange={handleVendorChange}
                                handleCompanyChange={handleCompanyChange}
                                handleInvoiceNumberChange={handleInvoiceNumberChange}
                                handleInvoiceDateChange={handleInvoiceDateChange}
                                handleDueDateChange={handleDueDateChange}
                                handleCurrencyChange={handleCurrencyChange}
                                handleAmountChange={handleAmountChange}
                                handleSubmit={handleSubmit}
                                addNewVendor={handleNewVendor}
                            />
                        </div>
                    </div>
                )
            )}
            {activeTab === 'submit' && (
                <>
                    <div className="submit-form-container">
                        <SubmitForm
                            onSubmit={handleFormSubmit}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            goBack={goBackSubmit}
                        />
                        <FinancialApproval value={financialController} onChange={handleFinancialControllerChange} controllerOptions={controllers} />
                    </div>
                </>
            )}
        </div>
    );
};

export default Invoice;

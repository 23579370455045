import React, { useState, useEffect } from 'react';
import { Table, Input, Select } from 'antd';
import { getUsers } from '../../api/UsersContainer';

const { Search } = Input;
const { Option } = Select;

const UserList = ({
    handleRoleChange,
}) => {
    const [searchText, setSearchText] = useState('');
    const [sortedInfo, setSortedInfo] = useState({});
    const [userData, setUserData] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUsers();
            setUserData(data);
            setRoles([
                { id: 1, name: 'Admin' },
                { id: 2, name: 'Sender' },
                { id: 3, name: 'Financial Controller' },
                { id: 4, name: 'First Approver' },
                { id: 5, name: 'Sender or Accountant' },
            ]);
        };

        fetchData();
    }, []);

    const styles = `
    .container {
        flex: 1;
        margin-left: -30px;
        margin-top: 30px;
    }
    .ant-table-thead > tr > th {
        background-color: #f0f2f5;
    }
    .ant-table-tbody > tr > td {
        background-color: white;
    }
    .ant-table-tbody > tr > td > .ant-select {
        width: 100%;
    }
    .ant-table-tbody > tr > td > .ant-select .ant-select-selection {
        width: 100%;
    }
    .ant-table-tbody > tr > td > .ant-select .ant-select-selection__rendered {
        width: 100%;
    }
    .ant-table-tbody > tr > td > .ant-select .ant-select-selection__rendered .ant-select-selection-selected-value {
        width: 100%;
    }
    .ant-table-tbody > tr > td > .ant-select .ant-select-arrow {
        right: 10px;
    }
    .ant-table-tbody > tr > td > .ant-select .ant-select-arrow .ant-select-arrow-icon {
        right: 0;
    }
    .ant-table-tbody > tr > td > .ant-select .ant-select-arrow .ant-select-arrow-icon::before {
        content: '>';
    }
    .ant-select {
        width: 100%; /* Adjust the width as needed */
    }
    .ant-select .ant-select-selection {
        width: 100%; /* Adjust the width as needed */
    }
    
`;
    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            sorter: (a, b) => a.role.localeCompare(b.role),
            sortOrder: sortedInfo.columnKey === 'role' && sortedInfo.order,
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: 200 }}
                    onChange={(value) => handleRoleChange(record.id, value, record.name, record.email)}
                >
                    {/* Map over roles and render an Option for each role */}
                    {roles.map((role) => (
                        <Option key={role.id} value={role.name}>{role.name}</Option>
                    ))}
                </Select>
            ),
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter); // Update sortedInfo state with the current sorting information
    };

    const filteredData = userData?.filter((user) =>
        Object.values(user).some((value) =>
            value.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    return (
        <div className="container">
            <style>{styles}</style>
            <Search
                placeholder="Search User"
                style={{ width: 200, marginBottom: 16 }}
                onChange={(e) => setSearchText(e.target.value)}
            />
            <Table
                columns={columns}
                dataSource={filteredData}
                onChange={handleTableChange}
                rowKey="id"
            />
        </div>
    );
};

export default UserList;

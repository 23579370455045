import React from 'react';
import AdjustableTextarea from '../atoms/AdjustableTextarea';
import { Checkbox } from 'antd';

const ApproveSubmitForm = ({ sender,
    senderValue,
    sendingDate,
    approvalDate,
    status,
    onSubmit,
    value,
    onChange,
    firstApproverComment,
    setFirstApproverComment,
    firstApprover,
    firstApproverCommentDate,
    checkBoxValue,
    onCheckBoxChange,
    role,
    onReject,
    onPay
}) => {
    const disabled = status === 'Approved' || status === 'Rejected' || status === 'Pending First Approval' || role === 'Sender or Accountant' || status === 'Paid';
    const buttonDisabled = (status === 'Approved' || status === 'Rejected' || status === 'Paid' ) || role === 'Sender or Accountant';
    const firstApproverDisabled = status !== 'Pending First Approval';
    const paidButtonDisabled = status !== 'Approved' || role !== 'Admin';

    const styles = `
    .submit-form {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        margin-left: 60px;
    }
    .submit-form label {
        margin-bottom: 10px;
    }
    .submit-form form {
        display: flex;
        flex-direction: column;
    }
    .submit-form .input-group {
        display: flex;
        flex-direction: column;
    }
    .submit-form button {
        background-color: #4CAF50;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        margin-top: 20px;
        width: 60%;
        margin-left: 10%;
    }
    .submit-form button:hover {
        background-color: #0056b3;
    }
    .submit-form button:active {
        background-color: #0056b3;
    }    
    .submit-form button:focus {
        outline: none;
    }
    .submit-form button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
    .reject-button {
        background-color: #c6131b;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        margin-top: -38px;
        width: 50%;
        margin-left: 5%;
    }
    .reject-button:hover {
        background-color: #cc0000;
    }
    .reject-button:active {
        background-color: #c6131b;
    }
    .reject-button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
    .reject-button:hover {
        background-color: #0056b3;
    }
    .reject-button:active {
        background-color: #0056b3;
    }
    .reject-button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
    .input-container {
        display: flex;
        flex-direction: column;
    }
    .input-container label {
        margin-bottom: 10px;
    }
    .input-container .input-group {
        display: flex;
        flex-direction: column;
    }
    .input-container .input-group button {
        background-color: #4CAF50;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        margin-top: 20px;
        width: 60%;
        margin-left: 60%;
    }
    .input-container .input-group button:hover {
        background-color: #0056b3;
    }
    .input-container .input-group button:active {
        background-color: #0056b3;
    }
    .input-container .input-group button:focus {
        outline: none;
    }
    .input-container .input-group button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
    .sender-container {
        display: flex;
        flex-direction: column;
        margin-left: 60px;
    }
    .sender-container label {
    }
    .sender-container .input-group {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
    .checkbox-group {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    .checkbox-group label {
        margin-left: 5px;
        margin-top: 10px;
    }    
    .custom-checkbox .ant-checkbox + span {
    font-size: 16px; /* Adjust font size as needed */
    margin-left: 10px; /* Adjust space between checkbox and label as needed */
    }
    .first-approver-container {
        display: flex;
        flex-direction: column;
        margin-left: 60px;
    }
    .first-approver-container label {
    }
    .first-approver-container .input-group {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
    .label-group {
        display: flex;
        flex-direction: row;
    }
    .label-group label {
        margin-top: 10px;
    }
    .pay-button {
        background-color: #4CAF50;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        margin-top: 20px;
        width: 50%;
        margin-left: 35%;
    }
    .pay-button:hover {
        background-color: #cc0000;
    }
    .pay-button:active {
        background-color: #c6131b;
    }
    .pay-button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
    .pay-button:hover {
        background-color: #0056b3;
    }
    .pay-button:active {
        background-color: #0056b3;
    }
    .pay-button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

    return (
        <div className="container">
            <style>{styles}</style>
            <div className="input-container">
                <div className="sender-container">
                    <div className="label-group">
                        <label>{sender}</label>
                        <label> - {sendingDate}</label>
                    </div>
                    <div className="input-group">
                        <AdjustableTextarea value={senderValue} disabled={true} />
                    </div>
                </div>
                {firstApprover ?
                    <div className="first-approver-container">
                        <div className="label-group">
                            <label>{firstApprover}</label>
                            <label> {firstApproverCommentDate ? `- ${firstApproverCommentDate}` : null} </label>
                        </div>
                        <div className="input-group">
                            <AdjustableTextarea value={firstApproverComment} onChange={setFirstApproverComment} disabled={firstApproverDisabled} />
                        </div>
                    </div>
                    : null}
                <div className="submit-form">
                    <div className="label-group">
                        <label htmlFor="comments">Financial Controller</label>
                        {approvalDate ? <label> - {approvalDate}</label> : null}
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="input-group">
                            <AdjustableTextarea value={value} onChange={onChange} disabled={disabled} />
                            <div className="checkbox-group">
                                <Checkbox checked={checkBoxValue} onChange={onCheckBoxChange} disabled={disabled}>
                                    Approve with Agreement
                                </Checkbox>
                            </div>
                            <button type="submit" disabled={buttonDisabled} >Approve</button>
                        </div>
                    </form>
                </div>
                <button className="reject-button" onClick={onReject} disabled={buttonDisabled}>Reject</button>
                <button className="pay-button" onClick={onPay} disabled={paidButtonDisabled}>Mark as Paid</button>
            </div>
        </div>
    );
};

export default ApproveSubmitForm;
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const LeftBar = () => {
    const location = useLocation();
    const { role, user } = useAuth();

    const leftBarStyle = {
        backgroundColor: "#f2f2f2", // Grey background color
        padding: "10px",
        width: "150px", // Adjust width as needed
    };

    const leftBarMenuStyle = {
        listStyleType: "none",
        padding: "0"
    };

    const leftBarMenuLiStyle = {
        marginBottom: "10px",
    };

    const leftBarMenuLinkStyle = {
        textDecoration: "none",
        color: "#000", // Black color for links
        display: "block", // Ensure links take up entire width of list items
        padding: "10px", // Add padding for better readability and clickability
        fontSize: "1.1em", // Corrected property name
        fontWeight: "500", // Corrected property name
        transition: "0.3s", // Add a smooth transition on hover
    };

    const leftBarMenuLinkActiveStyle = {
        backgroundColor: "#fff", // Light grey background color for active link
        textDecoration: "none",
        color: "#000", // Black color for links
        display: "block", // Ensure links take up entire width of list items
        padding: "10px", // Add padding for better readability and clickability
        fontSize: "1.1em", // Corrected property name
        fontWeight: "500", // Corrected property name
        transition: "0.3s", // Add a smooth transition on hover
    };

    const handleItemClick = (item) => {
    };

    return (
        <div className="left-bar" style={leftBarStyle}>
            <ul className="left-bar-menu" style={leftBarMenuStyle}>
                {role === 'Admin' || role=== 'Sender' && (
                    <li style={leftBarMenuLiStyle}>
                        <NavLink to="/" style={location.pathname === '/' ? leftBarMenuLinkActiveStyle : leftBarMenuLinkStyle} onClick={() => handleItemClick("Home")} className={location.pathname === '/' ? 'active' : ''}>Invoice</NavLink>
                    </li>
                )}
                <li style={leftBarMenuLiStyle}>
                    <NavLink to="/invoice-history" style={location.pathname === '/invoice-history' ? leftBarMenuLinkActiveStyle : leftBarMenuLinkStyle} onClick={() => handleItemClick("invoice-history")} className={location.pathname === '/invoice-history' ? 'active' : ''}>Invoice History</NavLink>
                </li>
                <li style={leftBarMenuLiStyle}>
                    <NavLink to="/vendors" style={location.pathname === '/vendors' ? leftBarMenuLinkActiveStyle : leftBarMenuLinkStyle} onClick={() => handleItemClick("vendors")} className={location.pathname === '/vendors' ? 'active' : ''}>Vendors</NavLink>
                </li>
                {role === 'Admin' && (
                    <li style={leftBarMenuLiStyle}>
                        <NavLink to="/users" style={location.pathname === '/users' ? leftBarMenuLinkActiveStyle : leftBarMenuLinkStyle} onClick={() => handleItemClick("users")} className={location.pathname === '/users' ? 'active' : ''}>Users</NavLink>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default LeftBar;
